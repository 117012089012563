<template>
  <div>
    <slot />
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>